<template>
<div class="markdown-container">
  <div class="markdown-body">
    <h1><a id="Kingschat_for_developers__User_authentication_0"></a>Kingschat for developers - User authentication</h1>
    <p>Kingschat for developers service gives you a way to allow users to login with Kingschat account to your application.</p>
    <h3><a id="Usage_Guide_5"></a>Usage Guide</h3>
    <ol>
      <li>Create project using <a href="https://developer.kingsch.at">Kingschat for developers panel</a>, be sure to provide email that will enable Kingschat to contact you in case of issues regarding your application.</li>
      <li>Create application within your project.</li>
    </ol>
    <ul>
      <li>Android application requires you to provide application name, application package name and application signature (SHA1)</li>
      <li>iOS application requires you to provide application name and application bundle ID.</li>
      <li>Web application - <em>coming soon!</em></li>
    </ul>
    <ol start="3">
      <li>Use information you provided and generated client ID to setup <a :href="androidDocumentationLink">Android</a>/<a :href="iosDocumentationLink">iOS</a> SDK</li>
      <li>Retrieve access token from SDK that will enable you to contact <b>Kingschat API</b> within scopes enabled by user during login process.</li>
      <li>Handle access token in your backend for further user authorization.</li>
    </ol>
    <h1><a id="Retrieving_Access_Token_16"></a>Retrieving Access Token</h1>
    <p>To retrieve Access Token you have to perform HTTP request:<br>
      <code>POST https://connect.kingsch.at/developer/oauth2/token</code><br>
      Body of the request should contain:
    </p>
    <pre><code>{
    &quot;grant_type&quot;: &quot;code&quot;,
    &quot;client_id&quot;: &lt;application_client_id&gt;,
    &quot;code&quot;: &lt;code&gt;
}</code></pre>
    <p>In case you want to refresh Access Token:</p>
    <pre><code>{
    &quot;grant_type&quot;: &quot;refresh_token&quot;,
    &quot;client_id&quot;: &lt;application_client_id&gt;,
    &quot;refresh_token&quot;: &lt;refresh_token&gt;
}</code></pre>
    <p>Success response:</p>
    <ul>
      <li>Status: 200</li>
    </ul>
    <pre><code>{
    &quot;access_token&quot;: &lt;access_token&gt;,
    &quot;expires_in_millis&quot;: &lt;expiration_time_millis&gt;,
    &quot;refresh_token&quot;: &lt;refresh_token&gt;
}</code></pre>
    <p>Error response:</p>
    <ul>
      <li>Status: 401 - User logged out</li>
      <li>Status: 403 - Invalid client application data</li>
    </ul>
<pre><code>{
    &quot;code&quot;: &lt;error_code&gt;,
    &quot;user_message&quot;: &lt;user_message&gt;
}</code></pre>
    <h1><a id="Kingschat_API_55"></a>Kingschat API</h1>
    <p>To use Kingschat API endpoints you must provide Access Token in specific header to authorize request.</p>
    <pre><code>{&quot;authorization&quot;: &quot;Bearer &lt;access_token&gt;&quot;}</code></pre>
    <h3><a id="User_scope_62"></a>User scope</h3>
    <p>User Profile<br>
      <code>GET https://connect.kingsch.at/developer/api/profile</code>
    </p>
    <ul>
      <li>Status: 200</li>
    </ul>
<pre><code>{
    profile: {
        id: &lt;user_unique_id&gt;,
        email: &lt;user_email&gt;,
        name: &lt;user_name&gt;,
        username: &lt;user_username&gt;,
        phone_number: &lt;user_phone_number&gt;,
        gender: &lt;user_gender&gt;,
        birth_date_millis: &lt;user_birth_date_millis&gt;,
        avatar: &lt;user_avatar_url&gt;
    }
}</code></pre>
    <ul>
      <li>Status: 401 - Authorization failed</li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: 'docs',
  data () {
    return {
      androidDocumentationLink: 'https://git.appunite.com/newmedia/kingschat-login-sdk/tree/sdk-impl',
      iosDocumentationLink: 'https://git.appunite.com/newmedia/kingschat-login-sdk/tree/sdk-impl'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./Docs";
</style>
